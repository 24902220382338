<template>
  <div>
    <equipment-list-table :filterCafeteria="cafeteria.id" />
  </div>
</template>

<script>
import EquipmentListTable from "../../../InventoryModule/EquipmentManagement/partials/EquipmentListTable.vue";

export default {
  name: "cafeteria-view-equipments",

  components: {
    EquipmentListTable,
  },

  props: ["cafeteria"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    cafeteria(cafeteria) {},
  },
};
</script>
